import { Grid } from "@mui/material";
import { useEffect } from 'react'
import { TableMantPrevIntervenciones } from "./TableMantPrevIntervenciones";
import { useLocation } from "react-router";
import queryString from 'query-string';
import { useLang } from "../../../../../../language";
import { clearEmptyKeys, getValuesParams, isChecked, setUrlByParams } from "../../../../../../helpers/helpers";
import { useForm } from "../../../../../../hooks/useForm";
import FilterAdvanced from "../../../../../../components/FilterAdvanced/FilterAdvanced";
import { FormSimple } from "./Filters/FormSimple";
import { groupBy } from "lodash";
import { MedidasServices } from "../PotencialMejoras/Medidas/controllers/medidas.services";
import { MejoraFasesServices } from "./Fases/controllers/fases.services";
import { MejorasNivelPrincipalServices } from "./controllers/mantpreventivo.services";
import { PotencialMejorasServices } from "../PotencialMejoras/controllers/potencialmejora.services";

export const PlanActuacion = ({ idedificio, id }: any) => {

    const Lang = useLang()

    const [fetchMedidasGET, fetchMedidasGETData] = MedidasServices.GET()

    const [fetchMejoraFases, fetchMejoraFasesData] = MejoraFasesServices.GET()

    const [fetchMejoraNivelPrincipal, fetchMejoraNivelPrincipalData] = MejorasNivelPrincipalServices.GET()

  const [fetchParamPotencialMejoraGET, fetchParamPotencialMejoraGETData] = PotencialMejorasServices.GET_PARAM_ARBOL()

    // Buscador

    const location = useLocation()

    const params = queryString.parse(location.search);

    const valuesSearch = {
        default: {
            magic: '',
            page: 1,
            limit: '800',
            order: '',
            edifici_id: idedificio,
            elemento: '',
            idnivelprincipal: '',
            idfase: '',
            seleccionado: 'true',
            excluir_asignados: [],
        },
        onlySimple: {},
        onlyExpanded: {},
    }

    const allValuesSearch = { ...valuesSearch.default, ...valuesSearch.onlySimple, ...valuesSearch.onlyExpanded }

    const [formValuesSearch, handleInputChangeSearch, handleFormChange, resetformValuesSearch] = useForm({
        page: params.page || allValuesSearch.page,
        limit: params.limit || allValuesSearch.limit,
        magic: params.magic || allValuesSearch.magic,
        order: params.order || allValuesSearch.order,
        edifici_id: params.edifici_id || allValuesSearch.edifici_id,
        elemento: params.elemento || allValuesSearch.elemento,
        idnivelprincipal: params.idnivelprincipal || allValuesSearch.idnivelprincipal,
        idfase: params.idfase || allValuesSearch.idfase,
        seleccionado: params.seleccionado || allValuesSearch.seleccionado,
        excluir_asignados: params.excluir_asignados ? params.excluir_asignados === 'true' ? ['excluir_asignados'] : [] : allValuesSearch.excluir_asignados,
    });

    const handleSubmitSearch = (e: any, formValues: any) => {

        e?.preventDefault();

        const urlParams: any = getValuesParams(formValues)        

        if (isChecked(urlParams.excluir_asignados)) {
            
            urlParams.excluir_asignados = true

        } else {

            delete urlParams.excluir_asignados
        }

        setUrlByParams(urlParams, 'limit', 'padre_id', 'seleccionado', 'edifici_id')

        if (urlParams.page !== 1) {

            handleFormChange({
                ...urlParams,
                excluir_asignados: formValues.excluir_asignados,
                page: 1
            });

        } else {

            let formValuesTemp = clearEmptyKeys(urlParams)

            fetchMedidasGET({
                params: {
                    ...formValuesTemp
                }
            })
        }
    }

    useEffect(() => {

        const urlParams: any = getValuesParams(formValuesSearch)

        if (isChecked(urlParams.excluir_asignados)) {
            
            urlParams.excluir_asignados = true
            
        } else {

            delete urlParams.excluir_asignados
        }
        
        setUrlByParams(urlParams, 'limit', 'padre_id', 'seleccionado', 'edifici_id')

        fetchMedidasGET({
            params: {
                ...urlParams
            }
        })

    }, [formValuesSearch.page, formValuesSearch.order, formValuesSearch.limit])

    useEffect(() => {
      
        fetchParamPotencialMejoraGET()
        
    }, []);

    useEffect(() => {

        if (idedificio) {

            fetchMejoraFases({
                params: {
                    edifici_id: idedificio,
                }
            })

            fetchMejoraNivelPrincipal({
                id: idedificio,
            })
        }

    }, [idedificio]);


    return (
        <Grid container spacing={2} columnSpacing={3}>

            <Grid item xs={12}>
                {
                    <div className="my-0 animate__animated animate__fadeIn animate__faster">

                        <FilterAdvanced
                            fetchData={fetchMedidasGETData}
                            handleSubmitSearch={handleSubmitSearch}
                            formValuesSearch={formValuesSearch}
                            handleFormChange={handleFormChange}
                            handleInputChangeSearch={handleInputChangeSearch}
                            resetformValuesSearch={resetformValuesSearch}
                            valuesDefault={valuesSearch.default}
                            valuesSimple={valuesSearch.onlySimple}
                            valuesExpanded={valuesSearch.onlyExpanded}
                        >

                            <FilterAdvanced.Simple label={Lang('BUSCADOR')}>

                                <FormSimple
                                    fetchMejoraFasesData={fetchMejoraFasesData}
                                    fetchMejoraNivelPrincipalData={fetchMejoraNivelPrincipalData}
                                />

                            </FilterAdvanced.Simple>

                            <FilterAdvanced.Expanded></FilterAdvanced.Expanded>

                        </FilterAdvanced>
                    </div>
                }
            </Grid>
            <Grid item xs={12} className="mb-5">
                <TableMantPrevIntervenciones
                    // data={fetchMedidasGETData.data?.items}
                    // data={dataTemp2}
                    data={groupBy(fetchMedidasGETData.data?.items, 'nivel_principal')}
                    fetchParamPotencialMejoraGETData={fetchParamPotencialMejoraGETData}
                    // loading={false}
                    loading={fetchMedidasGETData.loading}
                    total={fetchMedidasGETData.data?.total}
                    perPage={fetchMedidasGETData.data?.per_page}

                    formValuesSearch={formValuesSearch}
                    handleFormChange={handleFormChange}
                    handleInputChangeSearch={handleInputChangeSearch}

                    idedificio={idedificio}
                    fetchSuccess={() => handleSubmitSearch(null, formValuesSearch)}
                    id={id}
                    fetchMejoraFasesData={fetchMejoraFasesData}
                />
            </Grid>

        </Grid>
    );
};
