const PARRAFOS = {
    PARRAFO_CANCELAR_CUENTA: `Si lo deseas puedes cancelar tu cuenta. Si lo haces perderás el acceso a tus libros del edificio. Esta acción no puede deshacerse.`,
    PARRAFO_DATOS_NO_CAMBIAR: 'Los siguientes datos ya no se podrán cambiar una vez creado',

    PARRAFO_PASO_1_TITULO: `Edificios de viviendas de nueva construcción o provenientes de una gran rehabilitación`,
    PARRAFO_PASO_1_PARRAFO: `El libro del edificio debe formalizarse antes de la primera venta o transmisión de las viviendas de un edificio de nueva construcción o proveniente de una gran rehabilitación.`,
    PARRAFO_PASO_2_TITULO: `Edificios del sector terciario`,
    PARRAFO_PASO_2_PARRAFO: `El libro del edificio debe formalizarse antes de la primera venta o transmisión si el edificio es de nueva construcción o si se ha hecho una gran rehabilitación.`,
    PARRAFO_PASO_3_TITULO: `Edificios de viviendas existentes`,
    PARRAFO_PASO_3_PARRAFO: `Los propietarios de los inmuebles cuyo uso principal sea residencial tienen la obligación de conservarlos y rehabilitarlos de manera que siempre estén en condiciones de uso efectivo y adecuado.`,
    PARRAFO_REGISTRATE: `Si no dispones de un nombre de usuario y contraseña debes registrarte para acceder`,
    PARRAFO_NO_HAY_DATOS_PARA_MOSTRAR: 'No hay datos para mostrar',
    PARRAFO_IMAGENES_FORMATO_JGP_PNG: `Las imágenes pueden estar en formato JPG o PNG. Es preferible que no superen los 5MB.`,
    PARRAFO_IMAGENES_FORMATO_JGP_PNG_MB: `Las imágenes pueden estar en formato JPG o PNG. Es preferible que no superen los`,
    PARRAFO_ESCRIBA_NIF_USUARIO: `Escriba el NIF del usuario`,
    PARRAFO_DESEA_ELIMINAR_USUARIO: `¿Seguro que desea eliminar este usuario?`,
    PARRAFO_USUARIO_YA_REGISTRADO_EDIFICIO: 'El usuario ya se encuentra registrado en el edificio',
    
    PARRAFO_DESEA_RECHAZAR_ACCESO_EDIFICIO: `¿Seguro que desea rechazar el acceso a este edificio?`,
    PARRAFO_REF_CATASTRAL_NO_VALIDA: 'La referencia catastral no es válida',
    PARRAFO_HA_RECHAZADO_ACCESO_EDIFICIO_CONTACTE: `Ha rechazado el acceso al edificio`,
    PARRAFO_NECESITAS_REGISTRAR_DATOS_FACTURACION: 'Necesitas registrar tus datos de facturación para poder continuar',
    PARRAFO_TITULO_LISTADO_CONVENIOS: `Colegios profesionales y organizaciones en convenio`,
    PARRAFO_NAVEGUE_ARBOL_SELECCIONE: 'Navegue por el árbol y seleccione un elemento',
    PARRAFO_ARRASTRA_SUELTA_FICHEROS_CLIC: `Arrastra y suelta ficheros aquí, o haz clic para seleccionar ficheros`,
    PARRAFO_PROPUESTA_MEDIDAS_MEJORA: 'En este punto deben incluirse las diferentes propuestas que se plantean para mejorar las prestaciones detectadas, indicando una solución',
    PARRAFO_VENTAJAS_OBTENIDAS_MEJORA: 'En esta sección se indicarán los beneficios por los usuarios, obtenidos con las propuestas de las medidas de mejora planteadas',
    PARRAFO_COSTE_ECONOMICO_MEJORA: 'Se debe informar del coste (escogiendo una opción del listado), que suponen cada una de las propuestas de mejora planteadas, según el siguiente grado de cuantías',
    PARRAFO_DIFICULTAD_EJECUCION_MEJORA: 'En este apartado debe indicarse (escogiendo una opción del listado), el nivel de complejidad que supone la ejecución de la medida de mejora, según la siguiente categoría, razonando la situación concreta',
    PARRAFO_DESEA_ELIMINAR_PROPUESTA: `¿Seguro que desea eliminar esta propuesta?`,
    PARRAFO_DESEA_AGREGAR_ELEMENTOS_SELECCIONADOS_A_DEFICIENCIAS: `¿Seguro que desea agregar los elementos seleccionados a Deficiencias?`,
    PARRAFO_ADJUNTAR_IMAGENES_CREAR_DEFICIENCIA: `Para poder adjuntar imagenes, es necesario crear la actuación`,
    PARRAFO_NOTA_EMPRESA_PROFESIONAL: `NOTA: estos datos son identificativos de la empresa o profesional responsable del Libro del Edificio, los datos y la firma del autor/es del documento se informan en el apartado correspondiente.`,

    PARRAFO_DESEA_ELIMINAR_ELEMENTO: `¿Seguro que desea eliminar este elemento?`,
    PARRAFO_DESEA_ELIMINAR_ACTIVIDAD: `¿Seguro que desea eliminar esta actividad?`,
    PARRAFO_ADJUNTAR_CREAR_ANOTACION: `Para poder adjuntar imagenes o documentos, es necesario crear la anotación`,
    PARRAFO_DESEA_DESMARCAR_MEJORA: `¿Seguro que desea desmarcar esta mejora?`,
    PARRAFO_GENERANDO_DOCUMENTO_ESPERE: `Se está generando el documento, por favor espere`,
    PARRAFO_PROYECTO_SIN_FASES_AGREGAR_CONTINUAR: `Este proyecto no tiene fases, agregue una fase para continuar`,
    PARRAFO_PERIODO_SIN_FASES_AGREGAR_CONTINUAR: `Este periodo no tiene fases, agregue una fase para continuar`,
    PARRAFO_EDIFICIO_SIN_PERIODOS_AGREGAR_CONTINUAR: `Este edificio no tiene periodos, agregue un periodo para continuar`,
    PARRAFO_TEST_ENERGETICO: `Herramienta que ayuda a establecer las prioridades para planificar las mejoras que se incorporarán en el edificio para reducir la demanda energética y las emisiones de CO₂.`,
    PARRAFO_MARKETCONS: `Marketplace para la reutilización de productos de la construcción presentes en los edificios existentes.`,
    PARRAFO_CONSTRULEX: `Aplicación informática para obtener información de la legislación y reglamentación aplicable en obras de edificación.`,
    PARRAFO_CONSTRUDOC: `Aplicación informática para generar documentos técnicos para intervenciones en edificación: documentación de proyecto, documentación de seguridad y salud y documentación de calidad.`,
    PARRAFO_TEST_ALUMINOSO: `El test del cemento aluminoso es un servicio creado para determinar si una muestra de hormigón contiene o no cemento aluminoso.`,
    PARRAFO_TEST_RADON: `El Test Radón es un método sencillo al alcance de cualquier persona que quiera conocer la concentración de radón en su edificio, así como para profesionales que quieran dar cumplimiento normativo a sus proyectos de edificación.`,
    PARRAFO_PRUEBAS_DE_LABORATORIO: `Ensayos y pruebas de laboratorio ágiles y estandarizados, sobres las características técnicas más habituales requeridas a los productos de la construcción, para apoyar la tarea de los profesionales de la arquitectura técnica`,
    PARRAFO_APARATOS_DE_MEDIDA: `Empresas del sector que ofrecen la compra o el alquiler de herramientas y aparatos de medida con descuentos.`,
    PARRAFO_PORTAL_ITE: `Portal generador de informes de inspección técnica de los edificios (IITE) según modelo normalizado generado con la herramienta telemática habilitada por la Agencia de la Vivienda de Cataluña.`,
    PARRAFO_SEGURO_SALIR_CAMBIOS_SIN_GUARDAR: `Hay cambios sin guardar. ¿Seguro que desea salir?`,
    PARRAFO_FICHAS_DE_CAMPO: `Documento complementario para estudiar el potencial de mejoras en edificios existentes en la fase de inspección`,
    PARRAFO_CREAR_MEDIDA_COMENTARIO: `En comentarios puede indicarse, entre otros aspectos, si la mejora alcanza los niveles establecidos para edificios nuevos en la reglamentación, si la medida o conjuntos de medidas propuestas tienen en cuenta la forma de ocupación y el uso del espacio, así como la propia participación de las personas usuarias, etc.`,
    PARRAFO_PRECIOS_INCLUYEN_IVA: `Los precios incluyen el IVA`,
    PARRAFO_USUARIOS_CONVENIO_COMPRA: 'Los usuarios en convenio se validan en el momento de la compra',
    PARRAFO_CONSULTA_CONVENIOS: 'Consulta los colegios profesionales y organizaciones con convenio',
    PARRAFO_LEER_ACEPTAR_CONDICIONES_COMPRA: 'para leer y aceptar nuestras condiciones generales y particulares en el proceso de compra',
    PARRAFO_SELECCIONE_ACTIVIDAD_IMPORTAR: 'Seleccione una actividad para importar y complete los datos requeridos',
    PARRAFO_APROBACION_SISTEMA_FINANCIERO: 'Aprobación del sistema de financiación',
    PARRAFO_INDICAR_FECHA_JUNTA: 'Indicar fecha de la junta de la comunidad de propietarios u otra',
    PARRAFO_TITULO_AVISO_LEGAL: 'Aviso legal y política de privacidad',
    PARRAFO_LEER_ACEPTAR_CONDICIONES: 'para leer y aceptar nuestra política de privacidad',
    PARRAFO_MEDIDAS_SELECCIONADAS: 'Solo las medidas seleccionadas serán visibles al asignar las fases',
    PARRAFO_NIF_ASOCIADO_EXISTENTE: 'Este NIF ya se encuentra asociado a una cuenta',
    PARRAFO_INICIE_SESION_RESTABLEZCA_CONTRASENIA: 'Inicie sesión o restablezca su contraseña en caso la haya olvidado',
    PARRAFO_COLEGIADO_CATEB_NO_REGISTRARSE: `Si eres un colegiado del Cateb, no hace falta registrarse. Puedes acceder directamente con tus datos de colegiado/da`,
    PARRAFO_SELECCIONE_ROLES_USUARIO: 'Seleccione perfiles para el usuario',
    P_SUSCRIPCION_PREAVISO: `La suscripción se puede dar de baja con un preaviso de 30 días`,
    PARRAFO_DESEA_ELIMINAR_EDIFICIO: '¿Seguro que desea eliminar el edificio?',
    PARRAFO_DESEA_DAR_BAJA_SUSCRIPCION_EDIFICIO: '¿Seguro que desea dar de baja la suscripción del edificio?',
    P_EMPRESAS_COLABORADORAS: 'Conoce a las principales empresas de productos y servicios para la gestión del mantenimiento.',
    P_MONITORIZACION: 'Herramienta de análisis del comportamiento real del edificio mediante sensores colocados in-situ.',
    P_DOMOTICA_INMOTICA: 'Tecnología aplicada al control y la automatización inteligente de edificios.',
    P_ERROR_SINCRONIZACION_CATASTRO: 'Ha habido un error al sincronizar los datos de catastro, intentelo mas tarde.',
    P_ALTA_PRODUCTO_REPRESENTANTE_EDIFICIO: 'El alta del producto la realiza la propiedad o representante legal de la propiedad del edificio.',
    P_DATOS_ADMIN_LICENCIAS: 'Se harán constar las licencias en las que se ampara la construcción del edificio y los documentos que facultan la ocupación general del edificio, previa comunicación que, en su caso, se haya presentado en el Ayuntamiento, así como las licencias relativas a instalaciones comunes con especificación de la fecha de concesión o solicitud.',
    P_DATOS_ADMIN_OBRANUEVA: 'Se hará constar el nombre de los fedatarios públicos que han otorgado la escritura de declaración de obra nueva, el número de protocolo y la fecha; también se harán constar los datos de inscripción en el Registro de la escritura de declaración de obra nueva.',
    P_DATOS_ADMIN_PROPIEDAD: 'Se hará constar el régimen de propiedad del edificio y, en su caso, los datos de las escrituras de división en propiedad horizontal. También se hará constar la fecha de aprobación de los estatutos de la propiedad.',
    P_DATOS_ADMIN_ESPECIAL: 'Es necesario hacer constar los regímenes especiales de protección oficial que, en su caso, tenga el edificio, con expresión del documento acreditativo.',
    P_DATOS_ADMIN_CARGAS: 'Se harán constar las cargas que, en su caso, soporta el edificio y sus entidades jurídicas con expresión de los documentos que las acreditan.',
    P_DATOS_ADMIN_GAR_PROMOTOR: 'La garantía otorgada por los promotores que cubra la reparación de los defectos de la construcción y de los daños que se deriven directamente sobre el edificio de viviendas, cuando ésta sea exigible. En este apartado deben detallarse el vencimiento y la referencia de la póliza u otro documento acreditativo del otorgamiento de la garantía.',
    P_DATOS_ADMIN_GAR_INSTALACIONES: 'Las garantías específicas de las instalaciones. En este apartado deben detallarse aquellas instalaciones de elementos comunes del edificio que tienen una garantía, con expresión de su vencimiento y el nombre del garante.',
    P_DATOS_ADMIN_GAR_CONSTRUCTOR: 'La garantía otorgada por los constructores que cubra la reparación de los defectos de la construcción y de los daños que se deriven directamente sobre el edificio de viviendas, cuando ésta sea exigible. En este apartado deben detallarse el vencimiento y la referencia de la póliza u otro documento acreditativo del otorgamiento de la garantía.',
    P_CUMPLIMENTAR_EN_CASO_DISPONGA_INFORMACION: `Rellenar en caso de que se disponga de esta información`,
    P_CONSULTA_TECNICOS: `Consulta los técnicos de cabecera especialistas en gestión del mantenimiento`,
    P_DESCARGUE_PLANTILLA_SEPA: `Descargue la plantilla del documento SEPA, complete los datos y suba el documento aqui.`,
    P_PARA_REESTABLECER_CONTRASENIA: `Para restablecer su contraseña, es necesario que nos proporcione su NIF.`,
    P_SI_ES_COLEGIADO_CATEB: `Si es un colegiado Cateb, debe restablecer su contraseña en`,
    P_PUEDE_CONTINUAR_TRAMITE_EDIFICIO: `Puede continuar con el trámite de alta del edificio, sin embargo no se podrá hacer la gestión del mantenimiento del mismo hasta que no tengan dado de alta este perfil.`,
    P_PROPIEDAD_CONTRATADO_TECNICO: `¿La propiedad dispone de un técnico de cabecera especialista en gestión del mantenimiento?`,
    P_COSTE_ANUAL_DEPENDERA_ENTIDADES: `El coste anual de LED gestión dependerá del número de entidades jurídicas que conformen el edificio. Las entidades que se indiquen en este apartado son las que aparecerán en su edificio virtual.`,
    P_HERRAMIENTA_DIGITAL_REDACCION_CONSTRUCCION: `Aplicación informática para la redacción y formalización del Libro del edificio de nueva construcción o gran rehabilitación (edificios residenciales y terciarios) y también el Libro del edificio existente (edificios residenciales unifamiliares y plurifamiliares).`,
    P_HERRAMIENTA_DIGITAL_GESTION_RESIDENCIALES: `Plataforma digital por la gestión integral de los edificios que permite la interoperatividad entre profesionales, propietarios y usuarios en tiempo real, evitando gastos, reduciendo costes, revalorando el patrimonio, alargando la vida útil de los edificios y garantizando el cumplimiento legal.`,
    P_HERRAMIENTA_DIGITAL_SOPORTE_A: `la herramienta digital de soporte a la`,
    P_REDACCION_LIBRO_EDIFICIO: `redacción del libro del edificio`,
    P_TIPOS_EDIFICIOS_FORMALIZAR: `¿Qué tipos de edificios se pueden formalizar con esta herramienta?`,
    P_EDIFICIOS_VIVIENDAS_CONSTRUCCION: `Edificios de viviendas de nueva construcción o provenientes de una gran rehabilitación`,
    P_EDIFICIOS_SECTOR_TERCIARIO: `Edificios del sector terciario`,
    P_EDIFICIOS_VIVIENDAS_EXISTENTES: `Edificios de viviendas existentes`,
    P_TIPOS_EDIFICIOS_GESTIONAR: `¿Qué tipos de edificios se pueden gestionar con esta herramienta?`,
    P_HERRAMIENTA_DIGITAL_DE: `la herramienta digital de`,
    P_GESTION_INTEGRAL_EDIFICIOS: `gestión integral de edificios`,
    P_QUE_TE_PERMITE_HACER: `¿Qué te permite hacer`,
    P_PARA_USUARIO_REGISTRADO_APLICACION: `Para un usuario que se ha registrado en la aplicación para activar un edificio.`,
    P_PARA_INVITADO_EDIFICIO: `Para un invitado a un edificio que se le ha asignado su código personal de acceso.`,
    P_EJEMPLO_TEXTO_PRELIMINAR: `Ejemplo de texto preliminar del Plan`,
    P_EJEMPLO_TEXTO_PRE: `
    <p>
        En este apartado se introducirá el texto preliminar del Plan. Si se han cumplimentado las pantallas anteriores del Potencial de Mejora y del Plan de Actuaciones, el Plan incluirá:
    </p>
    <ul>
        <li>
            En su caso, las actuaciones urgentes (conservación/reparación de daños) que deban realizarse.
        </li>
        <li>
            En su caso, indicaciones para la racionalización del uso y la gestión del edificio hasta que se puedan hacer las obras o actuaciones de rehabilitación o renovación del edificio.
        </li>
        <li>
            Las medidas o conjuntos de medidas que permitan alcanzar un ahorro técnica y económicamente viable en consumo de energía primaria no renovable (Cep,nren) superior al 30 %, indicando el ahorro estimado en cada caso. La priorización de actuaciones tendrá en cuenta medidas o conjuntos de medidas, al menos, para cada uno de los tres niveles siguientes: reducción del consumo de energía primaria no renovable entre el 30 % y el 45 %, entre el 45 % y el 60 %, y superior al 60 %. En caso de que no sea posible alcanzar un ahorro en Cep,nren superior al 30 %, se habrá justificado dicha imposibilidad, así como el valor máximo de ahorro posible estimado.
        </li>
        <li>
            La reducción estimada del consumo de energía final (por vector energético) total y por servicios, así como el uso de energías renovables.
        </li>
        <li>
            Las medidas o conjuntos de medidas propuestas para tener en cuenta la forma de ocupación y el uso del espacio, así como la propia participación de las personas usuarias.
        </li>
        <li>
            Una valoración cualitativa o cuantitativa de las mejoras sobre el resto de las prestaciones del edificio, como la calidad ambiental interior, las condiciones de seguridad (de uso o en caso de incendio) o la accesibilidad.
        </li>
        <li>
        La descripción, como información al usuario, de las ventajas pretendidas con la mejora de las diferentes prestaciones derivada de la ejecución de la actuación o actuaciones propuestas.
        </li>
    </ul>
    `,
    P_ALTA_HACERLO_PROPIEDAD_REPRESENTANTE: `El alta del edificio tiene que hacerlo la propiedad o su representante legal.`,
    P_ENTIDADES_LOCAL_VIVIENDAS: `En caso de existir entidades con uso local la tarifa por entidad será igual que la de las viviendas. Las entidades con uso aparcamiento no tienen un coste asociado y no se deben incluir en el edificio virtual.`,
    PARRAFO_USARAN_ELEMENTOS_SELECCIONADOS: 'Se usarán los elementos seleccionados de la búsqueda actual',
    P_INFO_IMPORTAR_ITE: `El documento para importar tiene que haberse exportado del <a class="link-primary text-decoration-underline" href="https://ite.agenciahabitatge.cat/Ite/AppJava/views/login.xhtml" target="_blank" rel="noreferrer">Portal ITE de la Agència del Habitatge de Catalunya</a> en formato comprimido (.zip).`,
    P_NO_COMUNIDAD_AUTONOMA_CONTACTE: `Si su comunidad autónoma no está en este listado puede ponerse en contacto con el Colegio de arquitectura técnica de su provincia.`,
    P_INSTRUCCIONES_REF_CATASTRAL_1: `Para dar de alta un edificio es necesario incluir los 14 primeros dígitos de la referencia catastral, para identificar una entidad concreta del edificio deben ponerse los 20 dígitos.`,
    P_INSTRUCCIONES_REF_CATASTRAL_2: `El sistema se conectará a la base de datos de catastro, este proceso puede tardar unos minutos.`,
    P_NIF_ASOCIADO_EXISTENTE_2: 'En caso de ser un usuario ya registrado y haber olvidado la contraseña, es necesario restablecerla.',
    P_NIF_ASOCIADO_EXISTENTE_3: 'En caso de tener acceso a un edificio con código, puede terminar el registro mediante el proceso de restablecer contraseña.',
    P_ENTIDADES_EXCLUIR_APARCAMENTO: `Las únicas entidades jurídicas que se pueden excluir son las de uso aparcamiento, el resto deberán incorporarse al edificio virtual y se tendrán en cuenta para el cálculo de la subscripción.`,
    P_AL_GENERAR_PERIODICIDAD: `Al ingresar la periodicidad, se generarán las actividades subsecuentes hasta la fecha fin del proyecto.`,
    P_FORMULARIO_CONTACTO_ENVIADO: `El formulario de contacto se ha enviado correctamente. Nos pondremos en contacto contigo lo antes posible.`,
    P_NUMERO_COLEGIADO_PRECIO_CONVENIO: `Para aplicar el precio reducido de colegio en convenio, el número de colegiado/da indicado en este apartado tiene que coincidir con el informado al CGATE. En caso de duda ponte en contacto con tu colegio profesional.`,
    P_DOCUMENTOS_NO_DEBEN_PROTEGIDOS: `Los documentos que se quieran incluir en el Libro del edificio no deben estar bloqueados ni protegidos con contraseña.`,
    P_DOCUMENTOS_NO_DEBEN_PROTEGIDOS_2: `Los documentos que se quieran incluir no deben estar bloqueados ni protegidos con contraseña.`,
    P_ENTIDADES_INTRODUCCION: `Introducción de entidades jurídicas que conforman el edificio`,
    P_EDITAR_PERIODO: `Si se amplía la fecha fin del periodo, se generarán las actividades restantes hasta la nueva fecha fin del periodo.`,
    P_IMPORTAR_ACTIVIDAD: `Al ingresar la periodicidad, se generarán las actividades subsecuentes hasta la fecha fin del periodo.`,
    P_INDICA_PERIODOS: `Indica los periodos a incluir en el Programa de rehabilitación`,
    P_TRANSFERIR_EDIFICIO: `Al realizar esta acción se designará a la persona indicada como nuevo propietario del edificio virtual pudiendo así seguir editando los datos. Con esta acción el antiguo propietario dejará de poder acceder al edificio virtual ni consultar datos o documentos.`,
    P_IDENTIFICAR_SISTEMAS_ELEMENTOS: `Una vez identifique los sistemas y elementos constructivos del edificio se generarán las operaciones de mantenimiento asociadas de manera automática. Estas operaciones se generan en el idioma actual de la plataforma que solo se podrá cambiar volviendo a identificar los elementos constructivos. Le recomendamos que escoja en este momento el idioma deseado en la parte inferior derecha de la pantalla`,
    P_MODELO_CONTRATO_PRESTACION: `Modelo contrato prestación servicios`,
    P_MODELO_CONTRATO_PRESTACION_2:  `Modelo de contrato para la prestación de servicios profesionales en gestión del mantenimiento de edificios.`,
    P_INFORME_EVALUACION_IEEE: `Informe de evaluación del edificio IEE`,
    P_INFORME_EVALUACION_IEEE_2: `Portal generador de informes de evaluación del edificio (IEE) según el modelo normalizado generado con la herramienta telemática habilitada por el Ministerio de vivienda y agenda urbana del gobierno de España.`,
    P_INSPECCION_TECNICA_CASTILLA: `Inspección técnica de edificios de Castilla y León`,
    P_INSPECCION_TECNICA_CASTILLA_2: `Programa de ayuda para la realización de la inspección técnica y rehabilitación de edificios de Castilla y León.`,
    P_LIWEB: `Herramienta digital de soporte que agiliza la gestión informática del Libro de incidencias en las obras de construcción.`,
    P_AMPLIAR_TERCIARIO: `En estos momentos el producto LED gestión del mantenimiento no está habilitado para edificios terciarios. Estamos trabajando en esta actualización.`,
    P_SRI_DESC: `Aplicación web para calcular el indicador de preparación para aplicaciones inteligentes (SRI) mediante un esquema común de la UE de evaluación de la preparación inteligente de los edificios.`,
    P_INSPECCION_VASCO: `Inspección técnica de edificios del País Vasco`,
    p_INSPECCION_BASCO_DESC: `La ITE es la herramienta de aplicación en la Comunidad Autónoma del País Vasco, y es equivalente al IEE (Informe de Evaluación de Edificios). Ambas aplicaciones comparten el objeto y los apartados de inspección: Conservación, Accesibilidad y Eficiencia Energética.`,
    p_SEGURO_ELIMINAR_FASE: `¿Está seguro de que desea eliminar este período? Esta acción también eliminará sus fases, ingresos, derramas, actividades, gremios, documentos e imágenes asociadas a las actividades. Tenga en cuenta que esta acción no se podrá deshacer.`,
    P_SEGURO_ELIMINAR_ELEM: `Está solicitando la eliminación de un apartado que contiene datos que se borrarán. ¿Está seguro de que desea eliminarlo?`,
    P_SI_ELIMINAR_CAP: `SI, eliminar`,
    P_NO_SEGUIR_TRAB: `No, seguir trabajando`,

}

export default PARRAFOS;