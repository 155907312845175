import { useEffect } from "react"
import { useLang } from '../../../../language'
import { useForm } from '../../../../hooks/useForm'
import { useValidator } from '../../../../hooks/useValidator'
import { validExiste } from '../../../../helpers/validations'
import { isNumber } from 'lodash'
import { ButtonAdvanced } from '../../../../components/ButtonAdvanced/ButtonAdvanced'
import ModalAdvanced from '../../../../components/ModalAdvanced/ModalAdvanced'
import { Box, Grid, Typography } from '@mui/material'
import { isStatusSuccess } from '../../../../helpers/helpers'
import { ModalStatusServer } from '../../../../components/Modals/ModalStatusServer'
import { LoaderModalFormPrimary } from '../../../../components/Loaders/LoaderModalFormPrimary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InputTextarea } from "../../../../components/Inputs/InputTextarea"
import { ProyectoServices } from "./controllers/proyectos.services"
import { InputText } from "../../../../components/Inputs"
import { ModalConfirm } from "../../../../components/Modals/ModalConfirm"
import { useModalData } from "../../../../hooks/useModalData"

export const ModalProyecto = ({ data, handleClose, setproyectoSelected, idedificio, fetchSuccess,
    formValuesSearch, fetchParamsDocumentosGETData, id }: any) => {

    const Lang = useLang()

    const [dataModal_ConfirmDelete, handleShowModal_ConfirmDelete, handleCloseModal_ConfirmDelete] = useModalData()
    
    const [fetchProyectoGET, fetchProyectoGETData] = ProyectoServices.GET()

    const [fetchProyectoPOST, fetchProyectoPOSTData] = ProyectoServices.POST()

    const [fetchProyectoPATCH, fetchProyectoPATCHData] = ProyectoServices.PATCH()

    const [fetchProyectoDELETE, fetchProyectoDELETEData] = ProyectoServices.DELETE()

    const [formDocumento, handleInputDocumento, handleFormDocumento, resetFormDocumento] = useForm({
        tipus_document: '',
        obligatorietat: [],
        manual_habitatge: [],
    })

    const [validPeriodo, handleValidPeriodo, resetvalidPeriodo] = useValidator({
        nom: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data_inici: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
        data_fi: [
            { functionValid: validExiste, msgError: Lang('VALID_EXISTE_CAMPO_DEFAULT'), isOk: true },
        ],
    })

    // ------------ GET Documento

    useEffect(() => {

        if (isStatusSuccess(fetchProyectoGETData.status)) {

            handleFormDocumento({
                ...fetchProyectoGETData.data
            })
        }

    }, [fetchProyectoGETData]);


    // ------------ POST

    const handleSubmitDocumentoPOST = () => {

        if (handleValidPeriodo(formDocumento)) {

            fetchProyectoPOST({
                body: {
                    ...formDocumento,
                    edifici_id: idedificio
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchProyectoPOSTData.status)) {

            // fetchSuccess()
            // handleClose()
            // window.location.reload()
            setproyectoSelected(fetchProyectoPOSTData?.data?.data)
        }

    }, [fetchProyectoPOSTData]);


    // ------------ PATCH Agente

    const handleSubmitDocumentoPATCH = () => {

        if (handleValidPeriodo(formDocumento)) {

            fetchProyectoPATCH({
                id: data?.id,
                body: {
                    ...formDocumento,
                }
            })
        }
    }

    useEffect(() => {

        if (isStatusSuccess(fetchProyectoPATCHData.status)) {
            fetchSuccess()
            handleClose()
            window.location.reload()
        }

    }, [fetchProyectoPATCHData]);


    // ------------ DELETE Agente

    const handleSubmitEliminarAgente = () => {

        fetchProyectoDELETE({
            id: data?.id,
        })
    }

    useEffect(() => {

        if (isStatusSuccess(fetchProyectoDELETEData.status)) {
            
            fetchSuccess()
            handleClose()
            window.location.reload()
        }

    }, [fetchProyectoDELETEData]);


    useEffect(() => {

        if (data.value) {

            if (isNumber(data?.id)) {

                fetchProyectoGET({
                    id: data?.id
                })
            }

        } else {
            resetFormDocumento()
            resetvalidPeriodo()
        }

    }, [data.value]);

    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='675'>

                <ModalAdvanced.Head>

                    <Typography variant="h6" component="h2">
                        {data?.action === 'CREATE' ? Lang('NUEVO_PERIODO') : Lang('EDITAR_PERIODO')}
                    </Typography>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>
                    {
                        fetchProyectoGETData?.loading ?
                            <LoaderModalFormPrimary />
                            :
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                            >
                                <Grid container spacing={3} rowSpacing={3}>

                                    <Grid item xs={12} md={6}>
                                        <InputTextarea
                                            label={Lang('NOMBRE') + ': *'}
                                            name='nom'
                                            value={formDocumento.nom}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchProyectoPOSTData : fetchProyectoPATCHData}
                                            localValidation={validPeriodo.nom}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputTextarea
                                            label={Lang('DESCRIPCION') + ':'}
                                            name='descripcio'
                                            value={formDocumento.descripcio}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchProyectoPOSTData : fetchProyectoPATCHData}
                                            localValidation={validPeriodo.descripcio}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                        <InputText
                                            label={Lang('FECHA_INICIO') + ':'}
                                            name='data_inici'
                                            type='date'
                                            value={formDocumento.data_inici}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchProyectoPOSTData : fetchProyectoPATCHData}
                                            localValidation={validPeriodo.data_inici}
                                            className='labelFocusAlways'
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4} lg={4}>
                                        <InputText
                                            label={Lang('FECHA_FIN') + ':'}
                                            name='data_fi'
                                            type='date'
                                            value={formDocumento.data_fi}
                                            onChange={handleInputDocumento}
                                            fetchData={data?.action === 'CREATE' ? fetchProyectoPOSTData : fetchProyectoPATCHData}
                                            localValidation={validPeriodo.data_fi}
                                            className='labelFocusAlways'
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant='body1' className='mb-0' component="h5">
                                            {Lang('P_EDITAR_PERIODO')}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                    }

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    {
                        data?.action === 'CREATE' ?
                            <ButtonAdvanced
                                variant="contained" type="button" className="mt-3 ms-auto"
                                handleSubmit={handleSubmitDocumentoPOST}
                                loadings={[fetchProyectoPOSTData.loading]}
                                value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                            />
                            :
                            <div className='d-flex justify-content-between'>
                                <ButtonAdvanced
                                    variant="outlined" type="button" className="mt-3"
                                    handleSubmit={ handleShowModal_ConfirmDelete}
                                    // loadings={[.loading]}
                                    value={Lang('ELIMINAR')} icon={<FontAwesomeIcon icon={faTrash} className='me-2' />}
                                />
                                <ButtonAdvanced
                                    variant="contained" type="button" className="mt-3 ms-auto"
                                    handleSubmit={handleSubmitDocumentoPATCH}
                                    loadings={[fetchProyectoPATCHData.loading]}
                                    value={Lang('GUARDAR')} icon={<FontAwesomeIcon icon={faSave} className='me-2' />}
                                />
                            </div>
                    }

                </ModalAdvanced.Footer>

            </ModalAdvanced>

            <ModalStatusServer fetchData={fetchProyectoPOSTData} />

            <ModalStatusServer fetchData={fetchProyectoPATCHData} />

            <ModalStatusServer fetchData={fetchProyectoDELETEData} />

            <ModalConfirm
                data={dataModal_ConfirmDelete}
                handleClose={handleCloseModal_ConfirmDelete}
                message={<p>{Lang('P_SEGURO_ELIMINAR_ELEM')}</p>}
                textOnClickOk={Lang('P_SI_ELIMINAR_CAP')}
                variantClickOk='contained'
                onClickOK={handleSubmitEliminarAgente}
                fetchData={fetchProyectoDELETEData}
                textOnClickKO={Lang('P_NO_SEGUIR_TRAB')}
            />
        </>
    )
}