import { useNavigate, useParams } from 'react-router-dom';
import { DropdownNotifications } from '../DropdownNotifications/DropdownNotifications';
import { DropdownUser } from '../DropdownUser';
import { logOut } from '../../../store/slices/auth';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../store';
import { useLang } from '../../../language';
import { isAdministrador, openLinkByClick } from '../../../helpers/helpers';
import { TagIsAdmin } from '../TagIsAdmin/TagIsAdmin';
import { Button } from '@mui/material';
import { useModalData } from '../../../hooks/useModalData';
import { Faqs } from '../../../pages/Faqs';
import { DropdownLanguage } from '../DropdownLanguage';

interface INavleft {
    label?: string,
    auth?: any,
}

const NavTop = ({ label, auth }: INavleft) => {

    const Lang = useLang()

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { id } = useParams()

    const { language } = useSelector((state: RootState) => state.language)

    const getLinkFichaCampoLocale = () => {

        let nameLink = ''

        switch (language) {
            case 'ES':

                nameLink = 'LED_Ficha_de_campo.pdf'
                break;
            case 'CA':

                nameLink = 'LED_fitxa_de_camp.pdf'
                break;

            default:
                nameLink = ''
                break;

        }

        openLinkByClick(`${process.env.PUBLIC_URL}/files/${nameLink}`)
    }

    // const objectList = {
    //     title: Lang('SOPORTE'),
    //     list: [
    //         {
    //             content: <small className='text-wrap max-w-300px'>
    //                 <strong>{Lang('FICHAS_DE_CAMPO')}: </strong><span> {Lang('PARRAFO_FICHAS_DE_CAMPO')}</span>
    //             </small>,
    //             action: () => getLinkFichaCampoLocale()
    //         }
    //     ]
    // }
    const [dataModal_Faqs, handleShowModal_Faqs, handleCloseModal_Faqs] = useModalData()

    return (
        <section className='LayoutLibro__navtop--container'>
            <header className="LayoutLibro__NavTop d-flex align-items-center">

                <div className='px-3 d-flex justify-content-between w-100 align-items-center'>
                    <div className='d-flex'>
                        <img src={logo} alt="logo" className='cursor-pointer d-block d-lg-none' onClick={() => navigate(`/`)} />
                        <img src={`${process.env.PUBLIC_URL}/images/Logo_LED_${language}.png`} alt="logo" className='cursor-pointer d-none d-lg-block' onClick={() => navigate('/')} />

                        <div onClick={() => navigate(`/edificios/${id}`)} className="NavigationPromary__volver mt-auto ms-4">
                            <FontAwesomeIcon icon={faChevronLeft} className='me-2' /> {Lang('REGRESAR_AL_EDIFICIO')}
                        </div>
                    </div>

                    <div className='d-flex align-items-center'>

                        {/* <DropdownAdvanced
                            objectList={objectList}
                            className='me-4'
                        /> */}
                        <Button className='me-4 d-none d-lg-block' onClick={(e) => {
                            e.preventDefault();
                            handleShowModal_Faqs()
                        }}>
                            <FontAwesomeIcon icon={faQuestionCircle} className='me-2' />
                            <span className='text-capitalize'>FAQs</span>
                        </Button>

                        <DropdownNotifications />

                        <div className="d-none d-lg-block">
                            <DropdownLanguage />
                        </div>

                        <DropdownUser
                            dispatch={dispatch}
                            logOut={logOut}
                            auth={auth}
                        />

                        {
                            isAdministrador(auth?.user?.user?.role) &&
                            <TagIsAdmin />
                        }

                    </div>

                </div>

            </header>

            <Faqs
                data={dataModal_Faqs}
                handleClose={handleCloseModal_Faqs}
            />
        </section>
    )
}

export default NavTop